<template>
  <v-card tile :elevation="0" class="crud-box list-users">
    <h1 class="mx-4">Manage Users</h1>
    <v-layout wrap class="px-4 pt-4">
      <div style="overflow: visible">
        <v-btn v-if="isSuperAdmin"  color="success" class="mr-2" @click="onClickNew">
          <v-icon>mdi-plus</v-icon>
          New
        </v-btn>
        <v-btn v-if="isSuperAdmin && pickedItems.length > 0"  color="error" class="mr-2" @click="onClickDeleteAll">
          <v-icon>mdi-delete</v-icon>
          Delete Items
        </v-btn>
        <v-btn v-if="isSuperAdmin || isManager || isSupervisor"  color="primary" class="mr-2" @click="dialogImportOrder = true">
          <v-icon>mdi-database-import</v-icon>
          Import Multi Orders
        </v-btn>
        <v-btn v-if="isSuperAdmin || isManager || isSupervisor"  color="primary" class="mr-2" @click="dialogImportTracking = true">
          <v-icon>mdi-database-import</v-icon>
          Import Multi Tracking
        </v-btn>
      </div>
      <!---->
      <v-spacer></v-spacer>
      <v-layout wrap class="search-fields-page">
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Name"
                      v-model="searchFields.name.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Email"
                      v-model="searchFields.email.value"/>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeaderFilterByRole" :items="listItems" mobile-breakpoint="0" :server-items-length="tblPagination.total" :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>

        <template v-slot:item.actions="props">
          <v-tooltip bottom  v-if="isSuperAdmin || isSale || isManager || isSupervisor">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="clickImportOrderUser(props)" small color="warning" class="mr-2" v-bind="attrs" v-on="on">mdi-database-import</v-icon>
            </template>
            <span>Import Order</span>
          </v-tooltip>

          <v-icon v-if="isSuperAdmin || isManager" small @click="onClickDetail(props)" class="mr-2" color="primary">mdi-view-list</v-icon>
          <v-icon v-if="isSuperAdmin || isManager" small @click="onClickEdit(props)" class="mr-2" color="success">mdi-pencil</v-icon>
          <v-icon v-if="isSuperAdmin || isManager" small @click="onClickDelete(props)" color="error">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog.detail" :width="'60%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialogUpdate" :width="800">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :onCancel="()=>{dialogUpdate=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialogCreate" :width="800">
      <CreateItem :onCreated="onCreated" :onCancel="()=>{dialogCreate=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialogDelete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialogDelete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialogImportOrder" :width="400">
      <ImportOrders :itemSelect="itemSelect" :onCancel="()=>{dialogImportOrder=false}"></ImportOrders>
    </v-dialog>
    <v-dialog v-model="dialogImportTracking" :width="400">
      <ImportTrackings :onCancel="()=>{dialogImportTracking=false}"></ImportTrackings>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import DeleteItem from "@/admin/pages/ManageCustomers/Delete";
import CreateItem from "@/admin/pages/ManageCustomers/Create";
import UpdateItem from "@/admin/pages/ManageCustomers/Update";
import DetailItem from "@/admin/pages/ManageCustomers/Detail";
import ImportOrders from "@/admin/pages/ManageCustomers/ImportOrders";
import ImportTrackings from "@/admin/pages/ManageCustomers/ImportTrackings";
import indexMixin from './indexMixin';
import CONSTANTS from '@/config/constants'


export default {
  name: 'ManageCustomers',
  components: {ImportTrackings, ImportOrders, DeleteItem, CreateItem, UpdateItem, DetailItem},
  mixins: [indexMixin],
  data() {
    return {
      pickedItems: [],
      selectedItem: null,
      idsToDelete: [],
      tblLoading: false,
      dialogCreate: false,
      dialogUpdate: false,
      dialogDelete: false,
      dialogImportOrder: false,
      dialogImportTracking: false,
      itemSelect:null,
      searchFields: {
        name: {
          value: '',
          type: 'like',
        },
        email: {
          value: '',
          type: 'like',
        },
        role_id: {
          value: 2,
          type: '='
        },
      },
      querySilent: true,
      searchWith: 'role;rank;collaborator',
      searchWithCount: 'orders',
      tblConfig: {
        header: [
          {
            text: 'No',
            value: 'no',
            sortable: false,
            groupable: false,
            width: '55px',
            align: 'start',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Collaborator',
            value: 'collaborator.name',
          },
          {
            text: 'Phone',
            value: 'phone',
          },
          {
            text: 'Rank',
            value: 'rank.name',
          },
          {
            text: 'Balance',
            value: 'balance',
          },
          {
            text: 'Orders',
            value: 'orders_count',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            groupable: false,
            width: '135px',
            align: 'center',
          }
        ],
        footerProps: {
          showFirstLastPage: true,
          'items-per-page-options': [15, 25, 50, 100, -1]
        },
        showSelect: true,
        text: {
          loading: 'Loading ...',
          noItem: 'No item found'
        },
      },
      tblOptions: {}
    }
  },
  computed: {
    ...mapGetters({
      users: 'user/all',
      roles: 'role/all',
      roleById: 'role/getById',
      tblPagination: 'user/pagination'
    }),
    tblHeaderFilterByRole () {
      const header = JSON.parse(JSON.stringify(this.tblConfig.header));
      /*if (this.isSaleOrCollaborator) {
        const findIndexAction = header.findIndex((t) => t.value === 'actions');
        if (findIndexAction > -1) {
          header.splice(findIndexAction, 1);
        }
      }*/
      return header;
    },
    listItems() {
      return this.users.map(u => {
        u.role_name = u.role.name;
        return u;
      })
    },
    listSearchRoles() {
      let listSearchRoles = [
        {text: 'All Roles', value: null}
      ]
      this.roles.forEach(r => {
        listSearchRoles.push({
          text: r.name,
          value: r.id
        })
      });
      return listSearchRoles;
    },
    getIndexOfItem() {
      return item => {
        let pageOffset = this.tblPagination ? (this.tblPagination.current_page - 1) * this.tblPagination.per_page : 0
        return pageOffset + this.users.indexOf(item) + 1
      }
    },
    queryConf() {
      return {
        options: this.tblOptions,
        searchWith: this.searchWith,
        searchWithCount: this.searchWithCount,
        querySilent: this.querySilent
      }
    }
  },
  methods: {
    ...mapActions({
      getListItems: 'user/get',
      getAllRoles: 'role/all',
    }),
    onClickRow($event, row) {
      console.log('$event', $event);
      console.log('row', row);
    },

    onClickNew() {
      this.dialogCreate = true;
    },

    /**
     * open confirm box for delete 1 item
     * @param props
     */
    onClickDelete(props) {
      this.dialogDelete = true;
      this.idsToDelete = [props.item.id];
    },

    onClickEdit(props) {
      this.selectedItem = props.item;
      this.dialogUpdate = true;
    },

    clickImportOrderUser(props) {
      this.itemSelect = props.item.id;
      this.dialogImportOrder = true;
    },

    /**
     * open confirm box for delete multi item
     */
    onClickDeleteAll() {
      this.dialogDelete = true;
      this.idsToDelete = this.pickedItems.map(i => i.id);
    },

    /**
     * callback after delete items
     */
    onDeleted() {
      this.dialogDelete = false;
      this.idsToDelete = [];
      this.doSearch();
    },

    onCreated() {
      this.dialogCreate = false;
      this.doSearch();
    },

    onUpdated() {
      this.dialogUpdate = false;
      this.doSearch();
    },

    doSearch() {
      this.pickedItems = [];
      this.selectedItem = null;
      this.tblLoading = true
      this.getListItems({
        ...this.queryConf,
        searchFields: this.searchFields,
      }).then(res => {
        if (this.tblOptions.page > res.data.last_page) this.tblOptions.page = res.data.last_page;
      }).finally(() => this.tblLoading = false);
    },
  },
  watch: {
    'queryConf': {
      handler(val) {
        console.log('queryConf:', val);
        this.doSearch();
      },
      deep: true
    },
  },
  created() {
    console.log('roleById:', this.roleById(1));
  }
}
</script>
