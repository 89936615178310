<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Update user</v-card-title>
    <v-row>
      <v-col cols="6">
        <v-text-field dense outlined label="Name" v-model="item.name"/>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined label="Email" type="email" v-model="item.email"/>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined label="Phone Number" type="text" v-model="item.phone"/>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined label="Balance" type="number" v-model="item.balance"/>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager || isSale">
        <v-text-field dense outlined label="Address 2" type="text" v-model="item.address_2"/>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager || isSale">
        <v-text-field dense outlined label="Postcode" type="text" v-model="item.postcode"/>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager || isSale">
        <v-text-field dense outlined label="City" type="text" v-model="item.city"/>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager || isSale">
        <v-text-field dense outlined label="District" type="text" v-model="item.district"/>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager || isSale">
        <v-text-field dense outlined v-model="item.id_card" label="Identity Card"></v-text-field>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager || isSale">
        <v-text-field dense outlined label="Phone" type="text" v-model="item.phone"/>
      </v-col>
      <v-col cols="6">
        <v-select v-model="item.role_id" :items="rolesFiltered" item-text="name" item-value="id" label="Role" outlined
                  dense></v-select>
      </v-col>
      <v-col cols="6">
        <v-select v-model="item.rank_id" :items="customer_ranks" item-text="name" item-value="id" label="Role" outlined
                  dense></v-select>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager">
        <v-autocomplete
          :items="collaborators"
          v-model="item.collaborator_id"
          item-text="name"
          dense
          outlined
          :loading="loading"
          item-value="id"
          label="Collaborator"
        >
<!--          <template v-slot:append-item>
            <div v-intersect="endIntersect" />
          </template>-->
        </v-autocomplete>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager">
        <v-select v-model="item.additional_auto_buy_tracking_flg" :items="autoBuyTrackingNumber" item-text="name" item-value="id" label="Auto buy tracking" outlined
                  dense></v-select>
      </v-col>
      <v-col cols="6" v-if="isSuperAdmin || isManager">
        <v-select v-model="item.additional_auto_pay_order_flg" :items="autoPayOrder" item-text="name" item-value="id" label="Auto pay order" outlined
                  dense></v-select>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processUpdate">Update</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'UpdateUser',
  props: ['selectedItem', 'onUpdated', 'onCancel'],
  data: () => ({
    item: {},
    collaborators: [],
    loading: false,
    hasNext: true,
    page: 1,
    // role sale
    sales: [],
    loadingSale: false,
    hasNextSale: true,
    pageSale: 1,
    autoPayOrder: [
      {id: '0', name: 'No'},
      {id: '1', name: 'Yes'},
    ],
    autoBuyTrackingNumber: [
      {id: '0', name: 'No'},
      {id: '1', name: 'Yes'},
    ]
  }),
  computed: {
    ...mapGetters({
      roles: 'role/all',
      customer_ranks: 'customer_rank/all',
    }),
    isSuperAdmin(){
      return this.UserRole === this.CONSTANTS.ROLES.SUPER_ADMINISTRATOR
    },
    rolesFiltered() {
      if (!this.roles) return [];
      if (this.isSuperAdmin) return this.roles;
      return this.roles.filter(r => r.name !== 'Super Administrator')
    },
  },
  methods: {
    ...mapActions({
      updateItem: 'user/update',
      getAllRoles: 'role/all',
      getUsers: "user/getUsers",
    }),
    processUpdate() {
      if (this.isSuperAdmin || this.isManager) {
        if (!this.item.additional_auto_buy_tracking_flg || this.item.additional_auto_buy_tracking_flg == '') this.item.additional_auto_buy_tracking_flg = 0;
        if (!this.item.additional_auto_pay_order_flg || this.item.additional_auto_pay_order_flg == '') this.item.additional_auto_pay_order_flg = 0;
      }
      this.updateItem(this.item).then(() => {
        this.onUpdated();
      });
    },
    async getCollaboratorsFromApi() {
      this.loading = true;
      let request = {
        params: {
          limit: -1,
          page: this.page,
          silent: true,
          with: 'role;socialAccount',
          search: 'role_id:' + this.CONSTANTS.ROLES.COLLABORATOR,
          searchFields: 'role_id:=',
          searchJoin: 'AND'
        }
      }
      let collaboratorsData = await this.getUsers(request);
      this.collaborators = [...this.collaborators, ...collaboratorsData.data.data];
      request.params.search = 'role_id:' + this.CONSTANTS.ROLES.SALE;
      let salesData = await this.getUsers(request);
      this.collaborators = [...this.collaborators, ...salesData.data.data];
      this.loading = false;
    },
  },
  mounted() {
    if (this.roles.length < 1) this.getAllRoles();
    this.getCollaboratorsFromApi();
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) {
          this.item = this.selectedItem;
          if (this.item.additional_data && this.item.additional_data.auto_buy_tracking_flg)
            this.item.additional_auto_buy_tracking_flg = this.item.additional_data.auto_buy_tracking_flg
          if (this.item.additional_data && this.item.additional_data.auto_pay_order_flg)
            this.item.additional_auto_pay_order_flg = this.item.additional_data.auto_pay_order_flg
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
